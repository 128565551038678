$blue: #3e6ae1;

.has-border-primary {
  border: 1px solid hsl(var(--bulma-primary-h), var(--bulma-primary-s), var(--bulma-primary-l)) !important;
}

.has-text-blue {
  color: $blue !important;
}

.has-border-blue {
  border-color: $blue !important;
}

.has-blue-background {
  background-color: $blue !important;
}

.has-background-primary-gradient {
  background: linear-gradient(90deg, #e4032e 0%, #ed7004 100%);
}

.has-background-grey {
  background: #69748c;
}

.has-light-background {
  background: #e5eefd !important;
}

.has-lightgrey-background {
  background: #f2f2f2 !important;
}

.has-dark-background {
  background: #1e1e1e;
}

.has-text-shadow {
  text-shadow: 0px 0px 15px #000000;
}

.is-size-5-and-half {
  font-size: 18px !important;
}

.is-size-6-and-half {
  font-size: 14px !important;
}

.has-square-border {
  border-radius: 0px !important;
}

.has-slightly-rounded-border {
  border-radius: 8px !important;
}

.has-fit-cover {
  object-fit: cover;
}

.has-no-shadow {
  box-shadow: none !important;
}

.has-line-height-normal {
  line-height: normal;
}

.is-fullwidth-image {
  width: 100%;
}

.is-fullheight {
  height: 100%;
}

.is-max-width-100vw {
  max-width: 100vw;
}

.is-absolute {
  position: absolute;
}

.is-preline {
  white-space: pre-line;
}

.has-no-border {
  border: none !important;
}

.has-text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.is-absolute-centered {
  top: 0;
  bottom: 0;
  width: 100%;
  margin: auto;
  text-align: center;
  height: fit-content;
}

.is-vertically-centered {
  vertical-align: middle !important;
}

@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(66%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
