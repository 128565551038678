$theme: 'refititBlue';
.contact-success-notification {
  top: 100px;
  right: 30px;
  z-index: 10;
  max-width: 340px;
  position: fixed !important;
  .delete {
    top: 0.625rem !important;
    right: 0.8rem !important;
  }
  animation-duration: 0.75s;
  animation-name: slidein;
}

.contact-success-notification.mobile {
  top: 85px;
  right: 5px;
}

@keyframes slidein {
  from {
    translate: 150vw 0;
  }
  to {
    translate: 0 0;
  }
}
