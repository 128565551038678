$theme: 'refititBlue';
@use 'bulma/sass/utilities/derived-variables.scss' as dv with (
  $primary: #e4032e,
  $link: #ed7004,
  $danger: #e3032e,
  $warning: #f13d17,
  $success: #7fdcbb,
  $info: #dbe2e9
);

// At the end import all what has not yet been yet imported through customizations
@use 'bulma/bulma.scss';

// Import custom styles
@use './custom.scss';

// Font awesome
@use '@fortawesome/fontawesome-free/scss/brands' with (
  $fa-font-path: '~@fortawesome/fontawesome-free/webfonts/'
);
@use '@fortawesome/fontawesome-free/scss/fontawesome' with (
  $fa-font-path: '~@fortawesome/fontawesome-free/webfonts/'
);
@use '@fortawesome/fontawesome-free/scss/regular' with (
  $fa-font-path: '~@fortawesome/fontawesome-free/webfonts/'
);
@use '@fortawesome/fontawesome-free/scss/solid' with (
  $fa-font-path: '~@fortawesome/fontawesome-free/webfonts/'
);

.theme-light {
  --bulma-text: black !important;
  --bulma-body-color: black !important;
}

.theme-dark {
  --bulma-text: white !important;
  --bulma-body-color: white !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
button,
th,
.button {
  font-family: 'Inter', sans-serif;
  font-weight: 700 !important;
  font-style: normal;
}

p,
a,
td,
span {
  font-family: 'Inter', sans-serif;
  font-weight: 400 !important;
  font-style: normal;
}

.card,
.button,
.input,
select {
  border-radius: 0px !important;
}

.button.is-transparent {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.stimcar-light-button {
  @extend .button, .has-text-black, .has-background-white;
}

.stimcar-selected-light-button {
  @extend .button, .has-text-white, .has-blue-background;
}

.stimcar-dark-button {
  @extend .button, .has-text-white, .has-background-black;
}

.stimcar-selected-dark-button {
  @extend .button, .has-text-blue, .has-background-white;
}

.stimcar-gradient-button {
  @extend .button, .has-text-white, .has-background-primary-gradient, .has-no-border;
}

.stimcar-white-button {
  @extend .button, .has-background-white, .has-text-dark;
}

.stimcar-white-primary-button {
  @extend .button, .has-text-primary, .has-background-white, .has-no-border;
}

.stimcar-grey-button {
  @extend .button, .has-text-white, .has-background-grey;
}

.stimcar-lightblue-button {
  @extend .button, .has-light-background, .has-text-dark;
  border-radius: 0px;
}

.stimcar-white-button-with-blue-text-and-border {
  @extend .button, .has-text-blue, .has-background-white, .has-border-blue;
}

.stimcar-blue-button-with-white-text {
  @extend .button, .has-text-white, .has-blue-background;
}
