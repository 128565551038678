$theme: 'refititBlue';
.number-tag {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  border: 3px solid;
  .tag {
    background-color: transparent;
  }
  &.mobile {
    width: 24px;
    height: 24px;
  }
}
