$theme: 'refititBlue';
.contact-modal {
  .modal-card {
    .input,
    select {
      height: 36px !important;
    }
    .input.with-left-icon {
      padding-left: 38px !important;
    }
    .icon {
      height: 36px !important;
      width: 36px !important;
    }
  }
  .summary-table-price-cell {
    min-width: 145px;
  }
}
